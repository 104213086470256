import { Typography } from '@mui/material';
import React from 'react';

export const AppBarHeading: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Typography variant="h6" color="textPrimary" component="div" sx={{ whiteSpace: 'nowrap' }} noWrap>
      {children}
    </Typography>
  );
};
