import { TextField, TextFieldProps } from '@mui/material';
import { FieldPath, FieldValues } from 'react-hook-form';
import { HookFormProps, HookFormWrapper } from './HookFormWrapper';

type TextHookFormProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = HookFormProps<
  TFieldValues,
  TName
> &
  TextFieldProps;
export function TextHookForm<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  control,
  name,
  rules,
  helperText,
  ...fieldProps
}: TextHookFormProps<TFieldValues, TName>) {
  return (
    <HookFormWrapper
      helperText={helperText}
      control={control}
      rules={rules}
      name={name}
      Field={(hookFormProps) => <TextField variant="outlined" {...hookFormProps} {...fieldProps} />}
    />
  );
}
