import { projectPathResolvers } from '@/features/projects';
import { useProjectAreasQuery } from '@/features/projects/api/getProjectAreas';
import { useProjectState } from '@/hooks';
import storage from '@/utils/storage';
import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

export function RegistriesContainer({ children }: { children: React.ReactNode }) {
  const [project, setProject] = useProjectState();
  const projectAreasQuery = useProjectAreasQuery({
    onError: () => {
      enqueueSnackbar('Failed to load projects', { variant: 'error' });
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const storedSelectedProject = storage.getSelectedProject();
  const isValidProject = useCallback(
    (projectID: string) =>
      projectAreasQuery.data?.projectAreas.find((projectArea) => projectArea.project_area === projectID),
    [projectAreasQuery.data?.projectAreas],
  );

  useEffect(() => {
    if (projectAreasQuery.isSuccess && !projectAreasQuery.isFetching) {
      if (project === null) {
        if (storedSelectedProject && isValidProject(storedSelectedProject)) {
          setProject(storedSelectedProject);
        } else {
          navigate(projectPathResolvers.projects());
        }
      } else {
        if (!isValidProject(project)) {
          enqueueSnackbar(`Project with id '${project}' not found`, { variant: 'warning' });
        }
      }
    }
  }, [
    enqueueSnackbar,
    isValidProject,
    navigate,
    project,
    projectAreasQuery.isSuccess,
    projectAreasQuery.isFetching,
    setProject,
    storedSelectedProject,
  ]);

  if (projectAreasQuery.isError) {
    return <Navigate to={projectPathResolvers.projects()} />;
  }

  if (projectAreasQuery.isLoading) {
    return (
      <Box width="100%" height="calc(100vh - 48px)" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (projectAreasQuery.isSuccess && !projectAreasQuery.isFetching) {
    if (projectAreasQuery.data.projectAreas.length > 0) {
      if (project === null) {
        return (
          <Box width="100%" height="calc(100vh - 48px)" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        );
      } else {
        if (isValidProject(project)) {
          return <>{children}</>;
        } else {
          return <Navigate to={projectPathResolvers.projects()} />;
        }
      }
    } else {
      return <Navigate to={projectPathResolvers.projects()} />;
    }
  }

  return <>{children}</>;
}
