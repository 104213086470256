import { DetailContainer } from '@/components/DetailContainer';
import { ProtectedRouteIndex } from '@/routes/config';
import { lazyImport } from '@/utils/lazyImport';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { RegistriesContainer } from '../components/RegistriesContainer';
import { RegistryContainer } from '../components/RegistryContainer';

const { RegistryDevices } = lazyImport(() => import('../../devices/components/RegistryDevices'), 'RegistryDevices');
const { DevicesAuthentication } = lazyImport(
  () => import('@/features/devices/components/DevicesAuthentication'),
  'DevicesAuthentication',
);
const { DevicesDetail } = lazyImport(() => import('@/features/devices/components/DevicesDetail'), 'DevicesDetail');
const { DevicesEdit } = lazyImport(() => import('@/features/devices/components/DevicesEdit'), 'DevicesEdit');
const { DevicesHistory } = lazyImport(() => import('@/features/devices/components/DevicesHistory'), 'DevicesHistory');
const { DevicesNew } = lazyImport(() => import('@/features/devices/components/DevicesNew'), 'DevicesNew');
const { DevicesOverview } = lazyImport(
  () => import('@/features/devices/components/DevicesOverview'),
  'DevicesOverview',
);

const { RegistryGateways } = lazyImport(() => import('../../gateways/components/RegistryGateways'), 'RegistryGateways');
const { GatewaysAuthentication } = lazyImport(
  () => import('@/features/gateways/components/GatewaysAuthentication'),
  'GatewaysAuthentication',
);
const { GatewaysDetail } = lazyImport(() => import('@/features/gateways/components/GatewaysDetail'), 'GatewaysDetail');
const { GatewaysEdit } = lazyImport(() => import('@/features/gateways/components/GatewaysEdit'), 'GatewaysEdit');
const { GatewaysHistory } = lazyImport(
  () => import('@/features/gateways/components/GatewaysHistory'),
  'GatewaysHistory',
);
const { GatewaysNew } = lazyImport(() => import('@/features/gateways/components/GatewaysNew'), 'GatewaysNew');
const { GatewaysOverview } = lazyImport(
  () => import('@/features/gateways/components/GatewaysOverview'),
  'GatewaysOverview',
);
const { GatewaysBoundDevices } = lazyImport(
  () => import('@/features/gateways/components/GatewaysBoundDevices'),
  'GatewaysBoundDevices',
);

const { RegistriesIndex } = lazyImport(() => import('../components/RegistriesIndex'), 'RegistriesIndex');
const { RegistriesMigrate } = lazyImport(() => import('../components/RegistriesMigrate'), 'RegistriesMigrate');
const { RegistriesNew } = lazyImport(() => import('../components/RegistriesNew'), 'RegistriesNew');
const { RegistryDetail } = lazyImport(() => import('../components/RegistryDetail'), 'RegistryDetail');
const { RegistryAPIKeys } = lazyImport(() => import('../components/RegistryAPIKeys'), 'RegistryAPIKeys');
const { RegistryEdit } = lazyImport(() => import('../components/RegistryEdit'), 'RegistryEdit');
const { RegistryMonitoring } = lazyImport(
  () => import('@/features/monitoring/components/RegistryMonitoring'),
  'RegistryMonitoring',
);

export const RegistriesRoutes = () => {
  return (
    <Routes>
      <Route
        path={RegistriesRoutePaths.index}
        element={
          <RegistriesContainer>
            <Outlet />
          </RegistriesContainer>
        }
      >
        <Route index element={<RegistriesIndex />} />
        <Route path={RegistriesRoutePaths.new} element={<RegistriesNew />} />
        <Route path={RegistriesRoutePaths.migrate} element={<RegistriesMigrate />} />
      </Route>
      <Route
        path={`${RegistryRoutePaths.locations}/:${RegistryRouteParams.region}/${RegistryRoutePaths.registries}/:${RegistryRouteParams.registry}`}
        element={
          <RegistriesContainer>
            <RegistryContainer>
              <DetailContainer />
            </RegistryContainer>
          </RegistriesContainer>
        }
      >
        <Route index element={<Navigate to={RegistryRoutePaths.overview} />} />
        <Route path={RegistryRoutePaths.overview} element={<RegistryDetail />} />
        <Route path={RegistryRoutePaths.apiKeys} element={<RegistryAPIKeys />} />
        <Route path={DevicesRoutePaths.devices}>
          <Route index element={<RegistryDevices />} />
          <Route path={`${DevicesRoutePaths.new}`} element={<DevicesNew />} />
          <Route path={`:${RegistryRouteParams.device}/${DevicesRoutePaths.edit}`} element={<DevicesEdit />} />
          <Route path={`${DevicesRoutePaths.details}/:${RegistryRouteParams.device}`} element={<DevicesDetail />}>
            <Route index element={<Navigate to={RegistryRoutePaths.overview} />} />
            <Route path={DevicesRoutePaths.overview} element={<DevicesOverview />} />
            <Route path={DevicesRoutePaths.history} element={<DevicesHistory />} />
            <Route path={DevicesRoutePaths.authentication} element={<DevicesAuthentication />} />
          </Route>
        </Route>
        <Route path={GatewaysRoutePaths.gateways}>
          <Route index element={<RegistryGateways />} />
          <Route path={`${GatewaysRoutePaths.new}`} element={<GatewaysNew />} />
          <Route path={`:${RegistryRouteParams.gateway}/${GatewaysRoutePaths.edit}`} element={<GatewaysEdit />} />
          <Route path={`${GatewaysRoutePaths.details}/:${RegistryRouteParams.gateway}`} element={<GatewaysDetail />}>
            <Route index element={<Navigate to={GatewaysRoutePaths.overview} />} />
            <Route path={GatewaysRoutePaths.overview} element={<GatewaysOverview />} />
            <Route path={GatewaysRoutePaths.history} element={<GatewaysHistory />} />
            <Route path={GatewaysRoutePaths.boundDevices} element={<GatewaysBoundDevices />} />
            <Route path={GatewaysRoutePaths.authentication} element={<GatewaysAuthentication />} />
          </Route>
        </Route>
        <Route path={RegistryRoutePaths.monitoring} element={<RegistryMonitoring />} />
        <Route path={RegistryRoutePaths.edit} element={<RegistryEdit />} />
      </Route>
    </Routes>
  );
};

export const registriesPathResolvers = {
  index: ({ project }: { project: string }) => ({
    pathname: `/${ProtectedRouteIndex}/${RegistriesRoutePaths.index}`,
    search: `?project=${project}`,
  }),
  new: (params: { project: string }) => {
    const index = registriesPathResolvers.index(params);
    return {
      ...index,
      pathname: `${index.pathname}/${RegistriesRoutePaths.new}`,
    };
  },
  migrate: (params: { project: string }) => {
    const index = registriesPathResolvers.index(params);
    return {
      ...index,
      pathname: `${index.pathname}/${RegistriesRoutePaths.migrate}`,
    };
  },
};

interface RegistryPathParams {
  registryID: string;
  region: string;
  project: string;
}

export const registryPathResolvers = {
  index: ({ registryID, region, project }: RegistryPathParams) => ({
    pathname: `/${ProtectedRouteIndex}/${RegistryRoutePaths.locations}/${region}/${RegistryRoutePaths.registries}/${registryID}`,
    search: `?project=${project}`,
  }),
  overview: (registryParams: RegistryPathParams) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${RegistryRoutePaths.overview}`,
    };
  },
  edit: (registryParams: RegistryPathParams) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${RegistryRoutePaths.edit}`,
    };
  },
  apiKeys: (registryParams: RegistryPathParams) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${RegistryRoutePaths.apiKeys}`,
    };
  },

  monitoring: (registryParams: RegistryPathParams) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${RegistryRoutePaths.monitoring}`,
    };
  },
  devices: (registryParams: RegistryPathParams) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${DevicesRoutePaths.devices}`,
    };
  },
  deviceNew: (registryParams: RegistryPathParams) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${DevicesRoutePaths.devices}/${DevicesRoutePaths.new}`,
    };
  },
  deviceOverview: (registryParams: RegistryPathParams, deviceID: string) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${DevicesRoutePaths.devices}/${DevicesRoutePaths.details}/${encodeURIComponent(
        deviceID,
      )}/${DevicesRoutePaths.overview}`,
    };
  },
  deviceHistory: (registryParams: RegistryPathParams, deviceID: string) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${DevicesRoutePaths.devices}/${DevicesRoutePaths.details}/${encodeURIComponent(
        deviceID,
      )}/${DevicesRoutePaths.history}`,
    };
  },
  deviceAuthentication: (registryParams: RegistryPathParams, deviceID: string) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${DevicesRoutePaths.devices}/${DevicesRoutePaths.details}/${encodeURIComponent(
        deviceID,
      )}/${DevicesRoutePaths.authentication}`,
    };
  },
  deviceEdit: (registryParams: RegistryPathParams, deviceID: string) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${DevicesRoutePaths.devices}/${encodeURIComponent(deviceID)}/${
        DevicesRoutePaths.edit
      }`,
    };
  },
  gateways: (registryParams: RegistryPathParams) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${GatewaysRoutePaths.gateways}`,
    };
  },
  gatewayNew: (registryParams: RegistryPathParams) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${GatewaysRoutePaths.gateways}/${GatewaysRoutePaths.new}`,
    };
  },
  gatewayOverview: (registryParams: RegistryPathParams, gatewayID: string) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${GatewaysRoutePaths.gateways}/${GatewaysRoutePaths.details}/${gatewayID}/${GatewaysRoutePaths.overview}`,
    };
  },
  gatewayBoundDevices: (registryParams: RegistryPathParams, gatewayID: string) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${GatewaysRoutePaths.gateways}/${GatewaysRoutePaths.details}/${gatewayID}/${GatewaysRoutePaths.boundDevices}`,
    };
  },
  gatewayHistory: (registryParams: RegistryPathParams, gatewayID: string) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${GatewaysRoutePaths.gateways}/${GatewaysRoutePaths.details}/${gatewayID}/${GatewaysRoutePaths.history}`,
    };
  },
  gatewayAuthentication: (registryParams: RegistryPathParams, gatewayID: string) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${GatewaysRoutePaths.gateways}/${GatewaysRoutePaths.details}/${gatewayID}/${GatewaysRoutePaths.authentication}`,
    };
  },
  gatewayEdit: (registryParams: RegistryPathParams, gatewayID: string) => {
    const index = registryPathResolvers.index(registryParams);
    return {
      ...index,
      pathname: `${index.pathname}/${GatewaysRoutePaths.gateways}/${gatewayID}/${GatewaysRoutePaths.edit}`,
    };
  },
};

export const RegistryRouteParams = {
  region: 'region' as const,
  registry: 'registryID' as const,
  device: 'deviceName' as const,
  gateway: 'gatewayName' as const,
};

const RegistriesRoutePaths = {
  index: 'registries' as const,
  new: 'new' as const,
  migrate: 'migrate' as const,
};

const RegistryRoutePaths = {
  locations: 'locations' as const,
  registries: 'registries' as const,
  overview: 'overview' as const,
  monitoring: 'monitoring' as const,
  edit: 'edit' as const,
  apiKeys: 'api-keys' as const,
};

const DevicesRoutePaths = {
  devices: 'devices' as const,
  new: 'new' as const,
  edit: 'edit' as const,
  details: 'details' as const,
  overview: 'overview' as const,
  history: 'history' as const,
  boundDevices: 'boundDevices' as const,
  authentication: 'authentication' as const,
};

const GatewaysRoutePaths = {
  gateways: 'gateways' as const,
  new: 'new' as const,
  edit: 'edit' as const,
  details: 'details' as const,
  overview: 'overview' as const,
  history: 'history' as const,
  boundDevices: 'boundDevices' as const,
  authentication: 'authentication' as const,
};
