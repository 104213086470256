import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Card, CardContent, CardHeader, Collapse, Grid, Typography } from '@mui/material';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import React from 'react';

/**
 * CbErrorBoundary only used in development
 */
class CbErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  state = { error: '' };

  componentDidUpdate(prevProps: unknown) {
    if (this.state.error && prevProps !== this.props) {
      // reset if we currently have an error; gives new children a chance to succeed - something everything needs :)
      this.setState({
        error: '',
      });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Component Error:', error);
    console.error('Component Stack:', errorInfo.componentStack);
    this.setState({
      error: error.name,
    });
  }

  render() {
    if (this.state.error) {
      return (
        <BaseErrorCard
          title={this.state.error}
          mainError={'Something went wrong in this component. Check JS console for more info'}
        />
      );
    }

    return this.props.children;
  }
}

const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return process.env.NODE_ENV === 'production' ? (
    <SentryErrorBoundary showDialog>{children}</SentryErrorBoundary>
  ) : (
    <CbErrorBoundary>{children}</CbErrorBoundary>
  );
};

export default ErrorBoundary;

const BaseErrorCard = ({
  title,
  mainError,
  tryAgainAction,
  moreDetails,
}: {
  title: string;
  mainError: string;
  tryAgainAction?: () => void;
  moreDetails?: Record<string, string | number | boolean>;
}) => {
  return (
    <Card sx={{ margin: `auto`, maxWidth: 400 }}>
      <CardHeader
        sx={(theme) => ({
          backgroundColor: theme.palette.error.light,
          color: 'white',
        })}
        title={<Typography variant="overline">{title}</Typography>}
        avatar={<ErrorIcon />}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Error</b>: {mainError}
            </Typography>
          </Grid>
          {tryAgainAction && (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Button
                sx={(theme) => ({
                  backgroundColor: theme.palette.error.light,
                  '&:hover': {
                    backgroundColor: theme.palette.error.dark,
                  },
                  color: 'white',
                })}
                variant="contained"
                onClick={tryAgainAction}
                startIcon={<RefreshIcon />}
              >
                Try Again
              </Button>
            </Grid>
          )}
          {moreDetails && (
            <Grid item xs={12}>
              <Collapse>
                <Typography>More Details</Typography>
                {moreDetails &&
                  Object.keys(moreDetails).map((d) => (
                    <div key={`error_detail_${d}`}>
                      <b>{d}:</b> {moreDetails[d]}
                    </div>
                  ))}
              </Collapse>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
