import { styled, SxProps, Theme, Typography, TypographyProps, useTheme } from '@mui/material';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

const RouterLink = styled(Link)<{ color: TypographyProps['color'] }>(({ color }) => ({
  color,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: `underline solid currentcolor`,
    opacity: 0.8,
  },
  '&:active': {
    textDecoration: `underline solid currentcolor`,
  },
  cursor: 'pointer',
}));

export const InternalLink = ({
  typographySx,
  linkStyles,
  variant = 'body1',
  color,
  children,
  ...rest
}: {
  typographySx?: SxProps<Theme>;
  linkStyles?: React.CSSProperties;
  variant?: TypographyProps['variant'];
  color?: string;
  children: React.ReactNode;
} & LinkProps) => {
  const theme = useTheme();
  const defaultColor = theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light;
  return (
    <RouterLink
      color={color ?? defaultColor}
      style={{
        ...(linkStyles ?? {}),
      }}
      {...rest}
    >
      <Typography
        component={'span'}
        sx={[
          {
            text: {
              display: 'inline', // display: inline so that the link doesn't take up remaining horizontal space. this is needed when links are embedded inside list items
            },
          },
          ...(Array.isArray(typographySx) ? typographySx : [typographySx]),
        ]}
        color={color ?? defaultColor}
        variant={variant}
      >
        {children}
      </Typography>
    </RouterLink>
  );
};
