import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

/**
 * useCloseableSnackbar
 *
 * small abstraction that automatically includes the CloseIcon for the action.
 * users can override the action by supplying it in the options to enqueueSnackbar
 */
export function useCloseableSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const myEnqueueSnackbar: typeof enqueueSnackbar = useCallback(
    (message, options = {}) => {
      return enqueueSnackbar(message, {
        action: (key) => {
          return (
            <IconButton
              sx={(theme) => ({
                color: theme.palette.common.white,
              })}
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon />
            </IconButton>
          );
        },
        ...options,
      });
    },
    [closeSnackbar, enqueueSnackbar],
  );
  return { enqueueSnackbar: myEnqueueSnackbar, closeSnackbar };
}
