import Axios, { AxiosRequestConfig } from 'axios';

import { IoTCoreAdminSystemInfo } from '@/config';
import storage from '@/utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (!config.headers) {
    config.headers = {};
  }

  config.headers['ClearBlade-SystemKey'] = IoTCoreAdminSystemInfo.systemKey;
  config.headers['ClearBlade-SystemSecret'] = IoTCoreAdminSystemInfo.systemSecret;

  if (token) {
    config.headers['ClearBlade-UserToken'] = token;
  }

  config.headers.Accept = 'application/json';
  return config;
}

export let axiosAdminUserAccount = createAxios(IoTCoreAdminSystemInfo.URL);

function createAxios(url: string) {
  const myAxios = Axios.create({
    baseURL: url,
  });
  myAxios.interceptors.request.use(authRequestInterceptor);
  return myAxios;
}

export const setAxiosBaseURL = (url: string) => {
  axiosAdminUserAccount = createAxios(url);
};
