import { AccountSettings } from '@/components/AccountSettings';
import { Route, Routes } from 'react-router';
import { ProtectedRouteIndex } from './config';

export const AccountSettingsRoutes = () => {
  return (
    <Routes>
      <Route path={accountSettingsPath} element={<AccountSettings />} />
    </Routes>
  );
};

export const accountSettingsPathResolvers = {
  settings: () => `/${ProtectedRouteIndex}/${accountSettingsPath}`,
};

const accountSettingsPath = 'account-settings' as const;
