import { IoTCoreAdminSystemInfo } from '@/config';
import { axiosAdminNoAuth } from '@/lib/axiosAdminNoAuth';
import { useMutation } from '@tanstack/react-query';

export type OIDCAuthFormDataDTO = {
  provider_name: string;
};

const fetchOIDCAuthEndpoint = async (data: OIDCAuthFormDataDTO) => {
  const requestURL = `${location.protocol}//${location.hostname}/api/v/1/user/oidc/auth`;
  const resp = await axiosAdminNoAuth.post(requestURL, data, {
    headers: {
      'ClearBlade-SystemKey': `${IoTCoreAdminSystemInfo.systemKey}`,
      'ClearBlade-SystemSecret': `${IoTCoreAdminSystemInfo.systemSecret}`,
    },
  });

  window.location.href = resp.data.url;
};

export const useFetchOIDCAuthEndpoint = () => {
  return useMutation(fetchOIDCAuthEndpoint);
};
