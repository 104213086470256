import { useProjectValue } from '@/hooks';
import { setRegistryCredentials } from '@/lib/axiosTemplateSystem';
import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { registriesPathResolvers } from '..';
import { useRegistryCredentialsQuery } from '../api/getRegistryCredentials';
import { useRegistryRouteParams } from '../hooks/useRegistryRouteParams';

/**
 * RegistryContainer
 *
 * responsible for calling getRegistryCredentials and updating the axiosTemplateSystem base URL and token
 */
export function RegistryContainer({ children }: { children: React.ReactNode }) {
  const registryRouteParams = useRegistryRouteParams();
  const { project } = useProjectValue();
  const { enqueueSnackbar } = useSnackbar();
  const registryCredentialsQuery = useRegistryCredentialsQuery(
    {
      region: registryRouteParams.region,
      registry: registryRouteParams.registryID,
      project,
    },
    {
      onSuccess: (data) => {
        setRegistryCredentials(data);
      },
      onError: () => {
        enqueueSnackbar('Failed to fetch registry credentials', { variant: 'error' });
      },
    },
  );

  if (registryCredentialsQuery.isLoading) {
    return (
      <Box width="100%" height="calc(100vh - 48px)" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (registryCredentialsQuery.isError) {
    return <Navigate to={registriesPathResolvers.index({ project })} />;
  }

  return <>{children}</>;
}
