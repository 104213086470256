import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';
import { Box, Button, CircularProgress, CssBaseline, Typography } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from './mui-theme';

const ErrorFallback = () => {
  return (
    <Box display="flex" flexDirection="column" height="100vh" alignItems="center" justifyContent="center">
      <Box textAlign="center">
        <Typography variant="h2">Ooops, something went wrong :( </Typography>
        <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
          Refresh
        </Button>
      </Box>
    </Box>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools position="bottom-right" />}
          <MuiThemeProvider>
            <SnackbarProvider>
              <CssBaseline />
              <Suspense
                fallback={
                  <Box
                    sx={{
                      height: '100vh',
                      width: '100vw',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                <BrowserRouter basename="iot-core">
                  <AuthProvider>{children}</AuthProvider>
                </BrowserRouter>
              </Suspense>
            </SnackbarProvider>
          </MuiThemeProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};
