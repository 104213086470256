import { useRegistryRouteParams } from '@/features/registries/hooks/useRegistryRouteParams';
import { useIsMobile, useProjectValue } from '@/hooks';
import { useNavDrawerState } from '@/stores';
import DevicesIcon from '@mui/icons-material/DeviceHub';
import RegistryDetailsIcon from '@mui/icons-material/InfoOutlined';
import MonitoringIcon from '@mui/icons-material/MonitorHeart';
import GatewaysIcon from '@mui/icons-material/Router';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Path, useMatch, useResolvedPath } from 'react-router';
import { Link } from 'react-router-dom';
import { registriesPathResolvers, registryPathResolvers } from '../../features/registries/routes';

interface MenuListItem {
  path: Pick<Path, 'pathname' | 'search'>;
  icon?: React.ReactNode;
  text: string;
  level: number;
}

export const DetailMenu = ({ onClick }: { onClick?: () => void }) => {
  const isMobile = useIsMobile();
  const { registryID, region } = useRegistryRouteParams();
  const { project } = useProjectValue();
  const listItems: MenuListItem[] = [
    ...(isMobile
      ? [
          {
            path: registriesPathResolvers.index({ project }),
            text: 'Registries',
            level: 0,
          },
        ]
      : []),
    {
      path: registryPathResolvers.overview({ registryID, region, project }),
      icon: <RegistryDetailsIcon />,
      text: 'Registry Details',
      level: 1,
    },
    {
      path: registryPathResolvers.devices({ registryID, region, project }),
      icon: <DevicesIcon />,
      text: 'Devices',
      level: 1,
    },
    {
      path: registryPathResolvers.gateways({ registryID, region, project }),
      icon: <GatewaysIcon />,
      text: 'Gateways',
      level: 1,
    },
    {
      path: registryPathResolvers.monitoring({ registryID, region, project }),
      icon: <MonitoringIcon />,
      text: 'Monitoring',
      level: 1,
    },
  ];
  return (
    <List dense>
      {listItems.map((listItem) => (
        <MenuListItem key={listItem.path.pathname} listItem={listItem} onClick={onClick} />
      ))}
    </List>
  );
};

const MenuListItem: React.FC<{ listItem: MenuListItem; onClick?: () => void }> = ({ listItem, onClick }) => {
  const isMobile = useIsMobile();
  const { navDrawerOpen } = useNavDrawerState();
  const resolvedPath = useResolvedPath(listItem.path);
  const match = useMatch(resolvedPath.pathname);

  const ItemContent = (
    <ListItem disableGutters disablePadding onClick={onClick} sx={{ ...(isMobile ? { ml: listItem.level * 2 } : {}) }}>
      <ListItemButton selected={Boolean(match)} component={Link} to={listItem.path}>
        {listItem.icon && <ListItemIcon sx={(theme) => ({ minWidth: theme.spacing(5) })}>{listItem.icon}</ListItemIcon>}
        <ListItemText
          primary={listItem.text}
          sx={{
            whiteSpace: 'nowrap',
            ...(isMobile || navDrawerOpen ? { visibility: 'visible' } : { visibility: 'hidden' }),
          }}
        />
      </ListItemButton>
    </ListItem>
  );

  if (!isMobile && !navDrawerOpen) {
    return (
      <Tooltip placement="right" title={listItem.text}>
        {ItemContent}
      </Tooltip>
    );
  }
  return ItemContent;
};
