import { initReactQueryAuth } from './react-query-auth';

import {
  getUser,
  LoginCredentialsDTO,
  loginWithEmailAndPassword,
  RegisterCredentialsDTO,
  registerWithEmailAndPassword,
  UserLoginResponse,
} from '@/features/auth';
import { logout } from '@/features/auth/api/logout';
import storage from '@/utils/storage';
import { Box, LinearProgress, Typography } from '@mui/material';
import { setUser } from '@sentry/react';
import { Navigate, useSearchParams } from 'react-router-dom';

async function handleUserResponse(data: Pick<UserLoginResponse, 'user_token'>) {
  storage.setToken(data.user_token);
}

async function loadUser() {
  if (storage.getToken()) {
    const data = await getUser();
    setUser({ email: data.email });
    return data;
  }
  return null;
}

async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data);
  await handleUserResponse(response);
  const user = await loadUser();
  return user;
}

interface LoginWithTokenDTO {
  user_token: string;
}
async function loginWithTokenFn(data: LoginWithTokenDTO) {
  console.log('loginWithTokenFn::1');
  storage.setToken(data.user_token);
  console.log('loginWithTokenFn::2');
  const user = await loadUser();
  console.log('loginWithTokenFn::3', user);
  return user;
}

async function registerFn(data: RegisterCredentialsDTO) {
  const response = await registerWithEmailAndPassword(data);
  await handleUserResponse({ user_token: response.auth_token });
  const user = await loadUser();
  return user;
}

async function logoutFn() {
  await logout();
  storage.clearToken();
  window.location.assign(`${window.location.origin}/iot-core`);
}

export const { AuthProvider, useAuth } = initReactQueryAuth({
  loadUser,
  loginFn,
  loginWithTokenFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body2">Authenticating...</Typography>
          <LinearProgress sx={{ width: '100px' }} />
        </Box>
      </Box>
    );
  },
  ErrorComponent({ reset }) {
    storage.clearToken();
    reset();
    const [searchParams] = useSearchParams();
    return (
      <Navigate
        to={{
          pathname: '/',
          // pass any search params along (e.g., x-gcp-marketplace-token)
          search: `?${searchParams.toString()}`,
        }}
        replace
      />
    );
  },
});
