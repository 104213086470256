import { useNavigate } from 'react-router';

import Copyright from '@/components/Copyright';
import ExternalLink from '@/components/ExternalLink';
import { Head } from '@/components/Head';
import { authPathResolver } from '@/features/auth';
import { projectPathResolvers } from '@/features/projects';
import { useAuth } from '@/lib/auth';
import { Button, Divider, Grid, styled, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import landingBackground from './assets/landing-background.webp';
import IOTLogoLarge from './components/IOTLogoLarge';
import IOTLogoSmall from './components/IOTLogoSmall';

const smallLogoWidth = 178;
const smallLogoHeight = 48;
const largeLogoWidth = 492;
const largeLogoHeight = 298;
const watchWrapClass = 'watch-wrap';

const getStartedSx = { backgroundColor: 'landing.button', color: 'black' };

const StyledIotLogoSmall = styled(IOTLogoSmall)`
  color: white;
  width: 100% !important;
  height: 100% !important;
`;
const StyledIotLogoLarge = styled(IOTLogoLarge)`
  color: white;
  width: 100% !important;
  height: 100% !important;
`;

export const Landing = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (user) {
      navigate(projectPathResolvers.projects());
    }
  }, [navigate, user]);

  const handleLogin = () => {
    navigate({
      pathname: authPathResolver.login(),
      // pass any search params along (e.g., x-gcp-marketplace-token)
      search: `?${searchParams.toString()}`,
    });
  };

  const handleStart = () => {
    if (user) {
      navigate(projectPathResolvers.projects());
    } else {
      handleLogin();
    }
  };

  const detectWrap = (className: string) => {
    const items = document.getElementsByClassName(className);
    const docsItem = items[0]?.getBoundingClientRect();
    const buttonsItem = items[1]?.getBoundingClientRect();
    if ((docsItem?.top ?? 0) < (buttonsItem?.top ?? 0)) {
      setHeaderWrapped(true);
    } else {
      setHeaderWrapped(false);
    }
  };

  const [headerWrapped, setHeaderWrapped] = React.useState(false);

  React.useEffect(() => {
    // Initial detection on load
    detectWrap(watchWrapClass);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', function () {
      detectWrap(watchWrapClass);
    });
    () => {
      window.removeEventListener('resize', function () {
        detectWrap(watchWrapClass);
      });
    };
  }, []);

  return (
    <>
      <Head />

      <Grid
        container
        sx={{
          padding: 0,
          minHeight: '100vh',
          height: '100%',
          background: `url(${landingBackground}) #011923`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto',
        }}
        direction="column"
      >
        <Grid
          container
          item
          sx={{ padding: { xs: theme.spacing(2), md: theme.spacing(3, 6) } }}
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={9}
        >
          <Grid container item justifyContent="space-between" alignItems="flex-start" wrap="nowrap" spacing={2}>
            <Grid item sx={{ width: `${smallLogoWidth}px`, height: `${smallLogoHeight}px` }}>
              <StyledIotLogoSmall viewBox={`0 0 ${smallLogoWidth} ${smallLogoHeight}`} />
            </Grid>
            <Grid item>
              <Grid item container spacing={1} alignItems="center" justifyContent="flex-end" className={watchWrapClass}>
                <Grid item>
                  <Grid item container spacing={2} wrap="nowrap" justifyContent="flex-end">
                    <Grid item>
                      <ExternalLink
                        color="white"
                        underline="hover"
                        href="https://clearblade.atlassian.net/l/cp/2XodZMK2"
                      >
                        Docs
                      </ExternalLink>
                    </Grid>
                    <Grid item>
                      <Grid container item sx={{ height: '100%' }}>
                        <Grid item sx={{ height: '100%' }}>
                          <Divider
                            orientation="vertical"
                            sx={{
                              backgroundColor: 'common.white',
                              height: '100%',
                              ...(headerWrapped ? { display: 'none' } : {}),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className={watchWrapClass}>
                  <Grid item container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button sx={{ color: 'landing.button' }} onClick={handleLogin}>
                        log in
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" sx={getStartedSx} onClick={handleStart}>
                        Get Started
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item direction="column" spacing={4} alignItems="center" sx={{ marginTop: theme.spacing(2) }}>
            <Grid container item direction="column" alignItems="center" sx={{ maxWidth: '100vw !important' }}>
              <Grid
                item
                sx={{ width: `${largeLogoWidth}px`, height: `${largeLogoHeight}px`, maxWidth: '100% !important' }}
              >
                <StyledIotLogoLarge viewBox={`0 0 ${largeLogoWidth} ${largeLogoHeight}`} />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                align="center"
                variant="subtitle2"
                sx={(theme) => ({ color: 'landing.text', maxWidth: theme.spacing(60) })}
              >
                A fully managed service to easily and securely connect, manage, and ingest data from globally dispersed
                devices.
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" sx={getStartedSx} onClick={handleStart}>
                Get Started
              </Button>
            </Grid>
          </Grid>

          <Grid item container justifyContent="center">
            <Grid item>
              <Copyright sx={{ color: 'common.white' }} linkColor="white" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
