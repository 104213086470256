import Copyright from '@/components/Copyright';
import { TextHookForm } from '@/components/HookFormFields';
import { InputLabel } from '@/components/InputLabel';
import { InternalLink } from '@/components/InternalLink';
import { zodResolver } from '@hookform/resolvers/zod';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Avatar, Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import z from 'zod';
import { authPathResolver } from '.';
import { useSendPasswordResetLink } from '../api/sendPasswordResetLink';
import { Layout } from '../components/Layout';
import { alertParam } from '../utils/constants';

const baseUrl = 'iot-core/';

type ForgotPasswordForm =
  | {
      deliveryMode: 'email';
      email: string;
    }
  | { deliveryMode: 'sms'; phone: string }
  | { deliveryMode: undefined };

const forgotPasswordSchema = z
  .object({
    deliveryMode: z.union([z.literal('email'), z.literal('sms'), z.undefined()]),
    email: z.string().email(),
  })
  .superRefine((data, ctx) => {
    if (data.deliveryMode === 'email' && !data.email) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required',
        path: ['email'],
      });
      return false;
    }
    return true;
  });

export const ForgotPassword = () => {
  const loc = useLocation();
  const [error, setError] = useState<string | null>(null);
  const { control, reset, watch, handleSubmit, getValues } = useForm<ForgotPasswordForm>({
    defaultValues: {
      deliveryMode: undefined,
    },
    resolver: zodResolver(forgotPasswordSchema),
  });
  const sendPasswordResetLinkQuery = useSendPasswordResetLink({
    onSuccess: () => {
      setError(null);
    },
    onError: () => {
      setError(
        `There was an error sending your link to “${getValues('email')}”. Please check that the ${
          deliveryMode === 'email' ? 'email' : 'phone number'
        } you entered is correct.`,
      );
    },
  });

  const deliveryMode = watch('deliveryMode');

  useEffect(() => {
    if (loc.state && typeof loc.state === 'object') {
      setError(loc.state[alertParam as keyof typeof loc.state]);
      window.history.replaceState(null, '');
    }
  }, [loc, setError]);

  return (
    <Layout title="Forgot Password">
      <Avatar
        sx={(theme) => ({
          m: 1,
          backgroundColor: theme.palette.grey[400],
        })}
      >
        <LockOutlinedIcon />
      </Avatar>
      <Typography variant="h5">Forgot your password?</Typography>
      {!deliveryMode && (
        <Typography variant="body2" color="textSecondary">
          Select a verification method to reset your password.
        </Typography>
      )}
      {deliveryMode === 'email' && (
        <Typography variant="body2" color="textSecondary">
          To reset your password, please enter your email.
        </Typography>
      )}

      {error && (
        <Alert color="error" sx={{ width: '100%', mt: 4 }}>
          <AlertTitle>
            <Typography variant="body1">Link not sent</Typography>
          </AlertTitle>
          <Typography variant="body2">{error}</Typography>
        </Alert>
      )}
      {!deliveryMode && (
        <Box mt={4} width="100%">
          <Button
            sx={{ width: '100%', textTransform: 'none', borderColor: 'black', display: 'flex' }}
            variant="outlined"
            onClick={() => {
              reset({ deliveryMode: 'email', email: '' });
              setError(null);
            }}
          >
            <Box display="flex" flexWrap="nowrap" justifyContent="space-between" alignItems="center" width="100%">
              <Box display="flex" flexWrap="nowrap" alignItems="center">
                <MailOutlineIcon sx={{ mr: 2 }} />

                <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                  <Typography color="textPrimary" variant="body1" align="left">
                    Send e-mail
                  </Typography>
                  <Typography color="textSecondary" variant="body2" align="left">
                    Receive a link to reset your password via email.
                  </Typography>
                </Box>
              </Box>
              <NavigateNextIcon sx={{ color: 'text.primary', ml: 2 }} />
            </Box>
          </Button>
        </Box>
      )}
      {deliveryMode === 'email' && (
        <Box mt={4} width="100%">
          <form
            onSubmit={handleSubmit((values) => {
              if (values.deliveryMode === 'email') {
                sendPasswordResetLinkQuery.mutate({ email: values.email, baseUrl });
              }
            })}
          >
            {sendPasswordResetLinkQuery.isSuccess && (
              <Alert sx={{ my: 4 }}>
                <AlertTitle>
                  <Typography variant="body1">Email sent</Typography>
                </AlertTitle>
                <Typography variant="body2">
                  We sent an email to “{getValues('email')}” with a link to reset your password. It can take a few
                  minutes for the email to arrive.
                </Typography>
                <Typography sx={{ my: 2 }} variant="body2">
                  Didn&apos;t receive an email? Please check your spam folder.
                </Typography>
                <Button variant="outlined" type="submit" size="small">
                  Resend
                </Button>
              </Alert>
            )}
            <InputLabel label="Email" required />
            <TextHookForm
              autoFocus
              fullWidth
              control={control}
              name="email"
              size="small"
              placeholder="Enter your email"
            />
            {!sendPasswordResetLinkQuery.isSuccess && (
              <LoadingButton
                loading={sendPasswordResetLinkQuery.isLoading}
                sx={{ mt: 4 }}
                fullWidth
                variant="contained"
                type="submit"
                color="primary"
              >
                Send reset link
              </LoadingButton>
            )}
            <Box justifyContent="center" display="flex" sx={{ mt: 4 }}>
              <InternalLink to={authPathResolver.login()} color="primary">
                Back to log in
              </InternalLink>
            </Box>
          </form>
        </Box>
      )}
      <Box display="flex" mt={5} justifyContent="center">
        <Copyright />
      </Box>
    </Layout>
  );
};
