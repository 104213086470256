import Axios, { AxiosRequestConfig } from 'axios';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = getRegistryCredentials().serviceAccountToken;
  if (!config.headers) {
    config.headers = {};
  }
  if (token) {
    config.headers['ClearBlade-UserToken'] = token;
  }
  config.headers.Accept = 'application/json';
  return config;
}

/**
 * axiosTemplateSystem will be used to talk directly to a registry's template system.
 * the app will be responsible for updating the baseURL and user token whenever the user switches between registries
 */
export let axiosTemplateSystem = createAxios('');

function createAxios(url: string) {
  const myAxios = Axios.create({
    baseURL: url,
  });
  myAxios.interceptors.request.use(authRequestInterceptor);
  return myAxios;
}

let credentials: RegistryCredentials | null = null;

interface RegistryCredentials {
  systemKey: string;
  serviceAccountToken: string;
  url: string;
}

export function setRegistryCredentials(newCredentials: RegistryCredentials) {
  credentials = newCredentials;
  axiosTemplateSystem = createAxios(credentials.url);
}

function getRegistryCredentials(): RegistryCredentials {
  if (credentials === null) {
    throw new Error('Registry credentials not set');
  }
  return credentials;
}

export function getRegistrySystemKey(): string {
  return getRegistryCredentials().systemKey;
}
