import { useIsMobile } from '@/hooks';
import { useNavDrawerState } from '@/stores';
import { NavDrawerWidthCollapsed, NavDrawerWidthExpanded } from '@/utils/constants';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Dialog, Divider, Drawer, IconButton, List, ListItem, Toolbar } from '@mui/material';
import { DetailMenu } from './DetailMenu';

export default function NavDrawer() {
  const isMobile = useIsMobile();
  const { navDrawerOpen, toggleNavDrawer } = useNavDrawerState();

  if (isMobile) {
    return (
      <Dialog open={navDrawerOpen} onClose={toggleNavDrawer} fullScreen>
        <List dense>
          <ListItem sx={{ display: 'flex', justifyContent: 'flex-end', pt: 0, pb: 0 }}>
            <IconButton onClick={toggleNavDrawer}>
              <CloseIcon />
            </IconButton>
          </ListItem>
          <DetailMenu onClick={toggleNavDrawer} />
        </List>
      </Dialog>
    );
  }

  return (
    <Drawer
      PaperProps={{
        sx: (theme) => ({
          ...(navDrawerOpen ? { width: NavDrawerWidthExpanded } : { width: NavDrawerWidthCollapsed }),
          zIndex: theme.zIndex.navDrawer,
          boxSizing: 'border-box',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          mb: 0,
          overflowX: 'hidden',
        }),
      }}
      sx={(theme) => ({
        ...(navDrawerOpen ? { width: NavDrawerWidthExpanded } : { width: NavDrawerWidthCollapsed }),
        flexShrink: 0,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: theme.zIndex.navDrawer,
      })}
      variant="permanent"
      hideBackdrop
      open={navDrawerOpen}
      anchor="left"
    >
      <Toolbar variant="dense"></Toolbar>
      <Toolbar variant="dense"></Toolbar>
      <DetailMenu />
      <Box display="flex" justifyContent="center" sx={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
        <Divider />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.divider}`,
            width: '150%',
            ml: -1,
            mr: -1,
            p: 0.5,
          })}
        >
          <IconButton onClick={toggleNavDrawer}>
            {navDrawerOpen ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
}
