import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { OIDCRegisterForm } from '../components/OIDCRegisterForm';

export const OIDCRegister = () => {
  const location = useLocation();
  const providerName = location.state.providerName;

  return (
    <Layout title="Create an account">
      <Avatar
        sx={(theme) => ({
          m: 1,
          backgroundColor: theme.palette.grey[400],
        })}
      >
        <LockOutlinedIcon />
      </Avatar>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ fontSize: '23px', fontStyle: 'normal', fontWeight: 400, lineHeight: '32px' }}>
          Enter details to continue signing up with {providerName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Please provide the information below to finish signing up using your {providerName} account.
        </Typography>
      </Box>

      <Box mt={4}>
        <OIDCRegisterForm providerName={providerName} />
      </Box>
    </Layout>
  );
};
