import * as React from 'react';

// named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
export function lazyImport<T extends React.ComponentType<unknown>, I extends { [K2 in K]: T }, K extends keyof I>(
  factory: () => Promise<I>,
  name: K,
): I {
  try {
    return Object.create({
      [name]: React.lazy(async () => {
        // lazy with retry: https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb#file-lazywithretry-js
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
          window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
        );

        try {
          const component = await factory().then((module) => ({ default: module[name] }));

          window.localStorage.setItem('page-has-been-force-refreshed', 'false');

          return component;
        } catch (error) {
          if (!pageHasAlreadyBeenForceRefreshed) {
            window.localStorage.setItem('page-has-been-force-refreshed', 'true');
            window.location.reload();
            return {
              // note: I don't think this Reloading div will ever be visible since we call location.reload(). but we need to return a component, otherwise the 'Failed to fetch dynamically imported module' will bubble up
              default: (() => <div>Reloading...</div>) as unknown as I[K],
            };
          }
          throw error;
        }
      }),
    });
    // catching the error here so sentry no longer picks it up
  } catch (_error) {
    // returning this makes typescript happy.
    return Object.create(null);
  }
}

// Usage
// const { Home } = lazyImport(() => import("./Home"), "Home");
