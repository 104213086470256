import { axiosAdminUserAccount } from '@/lib/axiosAdminUserAccount';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export type GetUpdateRequestParams = {
  new_password: string;
  old_password: string;
};

export const updatePassword = async (data: GetUpdateRequestParams): Promise<void> => {
  const resp = await axiosAdminUserAccount.put('/api/v/1/user/pass', data);
  return resp.data;
};

export const resetPasswordQueryKeys = {
  all: (params: { email: string; baseUrl: string }) => [{ scope: 'resetPassword', params }] as const,
};

export const useUpdatePassword = <TContext>(
  options?: UseMutationOptions<unknown, unknown, GetUpdateRequestParams, TContext>,
) => {
  return useMutation((payload: GetUpdateRequestParams) => updatePassword(payload), options);
};
