import { axiosAdminUserAccount } from '@/lib/axiosAdminUserAccount';
import { useQuery } from '@tanstack/react-query';

async function getProviderList() {
  const response = await axiosAdminUserAccount.get<string[]>('/admin/settings/oidc/providers');
  return response.data;
}

export function useProviderList() {
  return useQuery(['providerList'], getProviderList);
}
