import Copyright from '@/components/Copyright';
import { TextHookForm } from '@/components/HookFormFields';
import { InputLabel } from '@/components/InputLabel';
import { projectPathResolvers } from '@/features/projects';
import { useAuth } from '@/lib/auth';
import { passwordRegex } from '@/utils/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { LoadingButton } from '@mui/lab';
import { Avatar, FormHelperText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import z from 'zod';
import { authPathResolver } from '.';
import { useGetResetRequest } from '../api/getResetRequest';
import { useResetPassword } from '../api/resetPassword';
import { Layout } from '../components/Layout';
import { LoginError } from '../components/LoginForm';
import { alertParam } from '../utils/constants';

type ResetPasswordForm = {
  password: string;
  passwordConfirm: string;
};

const resetPasswordSchema = z
  .object({
    password: z
      .string()
      .regex(
        passwordRegex,
        'Must contain at least 8 characters, 1 uppercase letter, 1 number, and 1 special character.',
      ),
    passwordConfirm: z
      .string()
      .regex(
        passwordRegex,
        'Must contain at least 8 characters, 1 uppercase letter, 1 number, and 1 special character.',
      ),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Passwords don't match",
    path: ['passwordConfirm'],
  });

export const ResetPassword = () => {
  const { login, isLoggingIn, loginIsError, loginError } = useAuth();
  const { control, handleSubmit, getValues } = useForm<ResetPasswordForm>({
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
    resolver: zodResolver(resetPasswordSchema),
  });
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchParams] = useSearchParams();
  const [resetToken, setResetToken] = useState<string>();
  const navigate = useNavigate();
  const getResetRequestQuery = useGetResetRequest({
    onError: () => {
      navigate(authPathResolver.forgotPassword(), {
        state: { [alertParam]: 'Your token is invalid or expired. Please send a new link to reset your password.' },
      });
    },
  });
  const resetPasswordQuery = useResetPassword({
    onSuccess: (resp) => {
      if (resp && typeof resp === 'object') {
        const email = resp.value.email;
        email && login({ email, password: getValues('password') });
      }
    },
  });

  useEffect(() => {
    searchParams.forEach((val, key) => {
      if (key === 'user_id') {
        setResetToken(val);
      }
    });
  }, [searchParams, setResetToken]);

  useEffect(() => {
    if (firstLoad && resetToken) {
      getResetRequestQuery.mutate({ pending_reset_id: resetToken });
      setFirstLoad(false);
    }
  }, [firstLoad, getResetRequestQuery, resetToken]);

  return (
    <Layout title="Reset Password">
      <Avatar
        sx={(theme) => ({
          m: 1,
          backgroundColor: theme.palette.grey[400],
        })}
      >
        <LockOutlinedIcon />
      </Avatar>
      {resetPasswordQuery.isSuccess ? (
        <>
          <Typography variant="h5">Success!</Typography>
          <Typography variant="body2" color="textSecondary">
            Your password has been reset.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h5">Reset password</Typography>
          <Typography variant="body2" color="textSecondary">
            Create a new password.
          </Typography>
        </>
      )}
      <Box mt={4} width="100%">
        {resetPasswordQuery.isSuccess ? (
          <>
            {loginIsError && (
              <Box my={4}>
                <LoginError error={loginError} />
              </Box>
            )}

            <LoadingButton
              loading={isLoggingIn}
              variant="contained"
              fullWidth
              onClick={() => navigate(projectPathResolvers.projects())}
            >
              Continue to app
            </LoadingButton>
          </>
        ) : (
          <form
            onSubmit={handleSubmit((values) => {
              resetPasswordQuery.mutate({
                pending_reset_id: resetToken,
                password: values.password,
              });
            })}
          >
            <InputLabel label="New password" required />
            <TextHookForm
              autoFocus
              fullWidth
              control={control}
              name="password"
              size="small"
              type="password"
              placeholder="Create a secure password"
            />
            <FormHelperText sx={{ mb: 4 }}>
              Must contain at least 8 characters, 1 uppercase letter, 1 number, and 1 special character.
            </FormHelperText>
            <InputLabel label="Confirm new password" required />
            <TextHookForm
              fullWidth
              control={control}
              name="passwordConfirm"
              size="small"
              type="password"
              placeholder="Re-enter password"
            />
            <FormHelperText>Must match password entered above.</FormHelperText>
            <LoadingButton
              loading={resetPasswordQuery.isLoading}
              sx={{ my: 4 }}
              fullWidth
              variant="contained"
              type="submit"
              color="primary"
            >
              Submit
            </LoadingButton>
          </form>
        )}
      </Box>

      <Box display="flex" mt={5} justifyContent="center">
        <Copyright />
      </Box>
    </Layout>
  );
};
