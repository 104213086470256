import { Alert, AlertTitle, Box, Button } from '@mui/material';

type ErrorAlertProps = {
  errorMessageTitle: string;
  errorMessageBody?: string;
  refetch?: () => void;
};

export const ErrorAlert = ({ errorMessageTitle, errorMessageBody, refetch }: ErrorAlertProps) => {
  console.error(`Error occured: ${errorMessageTitle}`);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Alert
        variant="outlined"
        action={
          refetch && (
            <Button color="inherit" size="small" onClick={() => refetch()}>
              RELOAD
            </Button>
          )
        }
        severity="error"
      >
        <AlertTitle>{errorMessageTitle}</AlertTitle>
        {errorMessageBody}
      </Alert>
    </Box>
  );
};
