import { Box, FormHelperText, Typography } from '@mui/material';
import {
  Control,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

export interface HookFormProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
  control: Control<TFieldValues>;
  name: TName;
  rules?: UseControllerProps<TFieldValues, TName>['rules'];
  helperText?: string;
}

interface HookFormWrapperProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends HookFormProps<TFieldValues, TName> {
  Field: (props: ControllerRenderProps<TFieldValues, TName> & { error?: boolean }) => JSX.Element;
}

export function HookFormWrapper<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  control,
  name,
  rules,
  helperText,
  Field,
}: HookFormWrapperProps<TFieldValues, TName>) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState,
  } = useController({
    rules,
    name,
    control,
  });
  return (
    <>
      {Field({ onChange, onBlur, value, name, ref, error: typeof fieldState.error !== 'undefined' })}
      <Box display="flex" flexDirection="column">
        {helperText && (
          <Typography variant="subtitle2" color="textSecondary">
            <FormHelperText>{helperText}</FormHelperText>
          </Typography>
        )}
        {fieldState.error && (
          <Typography variant="subtitle2">
            <FormHelperText error>{fieldState.error.message}</FormHelperText>
          </Typography>
        )}
      </Box>
    </>
  );
}
