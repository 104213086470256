import Copyright from '@/components/Copyright';
import { projectPathResolvers } from '@/features/projects';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Layout title="Log in">
      <Avatar
        sx={(theme) => ({
          m: 1,
          backgroundColor: theme.palette.grey[400],
        })}
      >
        <LockOutlinedIcon />
      </Avatar>
      <Typography variant="h5">Log in</Typography>
      <Typography variant="body2" color="textSecondary">
        Please enter your details.
      </Typography>

      <Box mt={3}>
        <LoginForm onSuccess={() => navigate(searchParams.get('redirect') ?? projectPathResolvers.projects())} />
      </Box>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Layout>
  );
};
