import { useParams } from 'react-router-dom';
import { RegistryRouteParams } from '../routes/index';

// todo: move these hooks outside to avoid circular dependencies

export function useRegistryRouteParams() {
  const { [RegistryRouteParams.registry]: registryID, [RegistryRouteParams.region]: region } = useParams();
  if (!registryID) {
    throw new Error('No registryID in route params');
  }
  if (!region) {
    throw new Error('No region in route params');
  }
  return { registryID, region };
}
