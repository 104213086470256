import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * note: useProjectState should only be used when you need to use the setter to set the project.
 * if you only want to read the current state for the project, use useProjectValue
 */
// todo: rename to useProjectSearchParamState ??
export function useProjectState(): [string | null, (project: string) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectSearchParamID = 'project';
  const projectSearchParam = searchParams.get(projectSearchParamID);

  const setProject = useCallback(
    (project: string) => {
      const newSearchParams = new URLSearchParams();
      newSearchParams.append(projectSearchParamID, project);
      setSearchParams(newSearchParams);
    },
    [setSearchParams],
  );

  return [projectSearchParam, setProject];
}

// todo: rename to useProjectSearchParamValue ??
export function useProjectValue(): { project: string } {
  const [project] = useProjectState();
  if (project === null) {
    throw new Error('Project is not set');
  }

  return { project };
}
