const storagePrefix = 'iot_core_';

const storage = {
  getToken: (): string | undefined => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
    } catch (e) {
      return undefined;
    }
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  getPaletteModePreference: (): unknown => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}palette_mode_preference`) as string);
  },
  setPaletteModePreference: (mode: 'light' | 'dark' | 'system') => {
    window.localStorage.setItem(`${storagePrefix}palette_mode_preference`, JSON.stringify(mode));
  },
  clearPaletteModePreference: () => {
    window.localStorage.removeItem(`${storagePrefix}palette_mode_preference`);
  },
  getSelectedProject: (): string | null => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}selected_project`) as string);
  },
  setSelectedProject: (selectedProject: string) => {
    window.localStorage.setItem(`${storagePrefix}selected_project`, JSON.stringify(selectedProject));
  },
  clearSelectedProject: () => {
    window.localStorage.removeItem(`${storagePrefix}selected_project`);
  },
};

export default storage;
