import cityLights from '../assets/stock/city-lights.webp';
import globeCity from '../assets/stock/globe-city.webp';
import touchScreen from '../assets/stock/touch-screen.webp';

// Note - We are using webp image formats instead to jpg, jpeg and png
// To convert to webp use - https://cloudconvert.com/webp-converter

const imageList = [globeCity, touchScreen, cityLights];
let selectedImageIndex: number | null = null;

export function getRandomStockPhoto() {
  if (selectedImageIndex !== null) {
    return imageList[selectedImageIndex];
  }
  // selectedImageIndex is used so that we show the same image when navigating between login and registration
  selectedImageIndex = Math.floor(Math.random() * imageList.length);
  return imageList[selectedImageIndex];
}
