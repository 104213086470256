import { Head } from '@/components/Head';
import { Box, Grid, Paper } from '@mui/material';
import React from 'react';
import { getRandomStockPhoto } from '../utils/getRandomStockPhoto';

export function Layout({ title, children }: { title: string; children: React.ReactNode }) {
  const randomStockPhoto = getRandomStockPhoto();
  return (
    <>
      <Head title={title} />
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={(theme) => ({
            backgroundImage: `url(${randomStockPhoto})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          })}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={(theme) => ({
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : '#303030',
          })}
        >
          <Box
            sx={{
              my: 5,
              mx: { xs: 2, sm: 4 },
              overflowY: 'auto',
              maxHeight: '90vh',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none',
              scrollbarWidth: 'none',
            }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
