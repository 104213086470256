import { IoTCoreAdminSystemInfo } from '@/config';
import { axiosAdminNoAuth } from '@/lib/axiosAdminNoAuth';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export type ResetPasswordParams = {
  email: string;
  baseUrl: string;
};

export const sendPasswordResetLink = async (data: ResetPasswordParams): Promise<unknown> => {
  const resp = await axiosAdminNoAuth.post(
    `/api/v/4/webhook/execute/${IoTCoreAdminSystemInfo.systemKey}/sendPasswordResetLink`,
    data,
  );
  return resp.data;
};

export const sendPasswordResetLinkQueryKeys = {
  all: (params: { email: string; baseUrl: string }) => [{ scope: 'sendPasswordResetLink', params }] as const,
};

export const useSendPasswordResetLink = <TContext>(
  options?: UseMutationOptions<unknown, unknown, ResetPasswordParams, TContext>,
) => {
  return useMutation((payload: ResetPasswordParams) => sendPasswordResetLink(payload), options);
};
