import {
  QueryObserverResult,
  RefetchOptions,
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { createContext, useCallback, useContext, useMemo } from 'react';

export interface AuthProviderConfig<
  User = unknown,
  Error = unknown,
  LoginCredentials = unknown,
  LoginWithTokenInfo = unknown,
  RegisterCredentials = unknown,
> {
  key?: string[];
  loadUser: (data: unknown) => Promise<User>;
  loginFn: (data: LoginCredentials) => Promise<User>;
  loginWithTokenFn: (data: LoginWithTokenInfo) => Promise<User>;
  registerFn: (data: RegisterCredentials) => Promise<User>;
  logoutFn: () => Promise<unknown>;
  waitInitial?: boolean;
  LoaderComponent?: () => JSX.Element;
  ErrorComponent?: (props: { error: Error | null; reset: () => void }) => JSX.Element;
}

export interface AuthContextValue<
  User = unknown,
  Error = unknown,
  LoginCredentials = unknown,
  LoginWithTokenInfo = unknown,
  RegisterCredentials = unknown,
> {
  user: User | undefined;
  login: UseMutateAsyncFunction<User, unknown, LoginCredentials>;
  loginWithToken: UseMutateAsyncFunction<User, unknown, LoginWithTokenInfo>;
  logout: UseMutateAsyncFunction<unknown, unknown, void, unknown>;
  register: UseMutateAsyncFunction<User, unknown, RegisterCredentials>;
  isLoggingIn: boolean;
  loginIsError: boolean;
  loginError: unknown;
  isLoggingInWithToken: boolean;
  loginWithTokenIsError: boolean;
  loginWithTokenError: unknown;
  isLoggingOut: boolean;
  isRegistering: boolean;
  refetchUser: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<User, Error>>;
  error: Error | null;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext(null);
AuthContext.displayName = 'AuthContext';

export function initReactQueryAuth<
  User = unknown,
  Error = unknown,
  LoginCredentials = unknown,
  LoginWithTokenInfo = unknown,
  RegisterCredentials = unknown,
>(config: AuthProviderConfig<User, Error, LoginCredentials, LoginWithTokenInfo, RegisterCredentials>) {
  const TypedAuthContext = AuthContext as React.Context<AuthContextValue<
    User,
    Error,
    LoginCredentials,
    LoginWithTokenInfo,
    RegisterCredentials
  > | null>;

  const {
    loadUser,
    loginFn,
    loginWithTokenFn,
    registerFn,
    logoutFn,
    key = ['auth-user'],
    waitInitial = true,
    LoaderComponent = () => <div>Loading...</div>,
    ErrorComponent = (error: unknown) => <div style={{ color: 'tomato' }}>{JSON.stringify(error, null, 2)}</div>,
  } = config;

  function AuthProvider({ children }: AuthProviderProps): JSX.Element {
    const queryClient = useQueryClient();

    const {
      data: user,
      error,
      status,
      isLoading,
      isSuccess,
      refetch,
    } = useQuery<User, Error>({
      queryKey: key,
      queryFn: loadUser,
      retry: false,
    });

    const setUser = useCallback((data: User) => queryClient.setQueryData(key, data), [queryClient]);

    const loginMutation = useMutation({
      mutationFn: loginFn,
      onSuccess: (user) => {
        setUser(user);
      },
    });

    const loginWithTokenMutation = useMutation({
      mutationFn: loginWithTokenFn,
      onSuccess: (user) => {
        console.log('loginWithTokenMutation onSuccess', user);
        setUser(user);
      },
    });

    const registerMutation = useMutation({
      mutationFn: registerFn,
      onSuccess: (user) => {
        setUser(user);
      },
    });

    const logoutMutation = useMutation({
      mutationFn: logoutFn,
      onSuccess: () => {
        queryClient.clear();
      },
    });

    const value = useMemo(
      () => ({
        user,
        error,
        refetchUser: refetch,
        login: loginMutation.mutateAsync,
        isLoggingIn: loginMutation.isLoading,
        loginIsError: loginMutation.isError,
        loginError: loginMutation.error,
        loginWithToken: loginWithTokenMutation.mutateAsync,
        isLoggingInWithToken: loginWithTokenMutation.isLoading,
        loginWithTokenIsError: loginWithTokenMutation.isError,
        loginWithTokenError: loginWithTokenMutation.error,
        logout: logoutMutation.mutateAsync,
        isLoggingOut: logoutMutation.isLoading,
        register: registerMutation.mutateAsync,
        isRegistering: registerMutation.isLoading,
      }),
      [
        user,
        error,
        refetch,
        loginMutation.mutateAsync,
        loginMutation.isLoading,
        loginMutation.isError,
        loginMutation.error,
        loginWithTokenMutation.mutateAsync,
        loginWithTokenMutation.isLoading,
        loginWithTokenMutation.isError,
        loginWithTokenMutation.error,
        logoutMutation.mutateAsync,
        logoutMutation.isLoading,
        registerMutation.mutateAsync,
        registerMutation.isLoading,
      ],
    );

    if (isSuccess || !waitInitial) {
      return <TypedAuthContext.Provider value={value}>{children}</TypedAuthContext.Provider>;
    }

    if (isLoading) {
      return <LoaderComponent />;
    }

    if (error) {
      return (
        <ErrorComponent
          error={error}
          reset={() => {
            queryClient.resetQueries(key);
          }}
        />
      );
    }

    return <div>Unhandled status: {status}</div>;
  }

  function useAuth() {
    const context = useContext(TypedAuthContext);
    if (!context) {
      throw new Error(`useAuth must be used within an AuthProvider`);
    }
    return context;
  }

  return { AuthProvider, AuthConsumer: TypedAuthContext.Consumer, useAuth };
}
