import { Head } from '@/components/Head';
import { projectPathResolvers } from '@/features/projects';
import { useAuth } from '@/lib/auth';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { authPathResolver } from '.';
import { ErrorAlert } from '../components/ErrorAlert';
import { getRandomStockPhoto } from '../utils/getRandomStockPhoto';

export const OIDCToken = () => {
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get('user_token');
  const { loginWithToken, loginWithTokenIsError, loginWithTokenError, user } = useAuth();
  const randomStockPhoto = getRandomStockPhoto();

  useEffect(() => {
    if (userToken) {
      loginWithToken({ user_token: userToken });
    }
  }, [loginWithToken, userToken]);

  if (userToken === null) {
    return <Navigate to={authPathResolver.login()} />;
  }

  // if there isn't a user yet, that means we're still loading the user information
  if (!user) {
    const title = 'Token';
    return (
      <>
        <Head title={title} />
        <Grid container component="main" sx={{ height: '100vh' }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={(theme) => ({
              backgroundImage: `url(${randomStockPhoto})`,
              backgroundRepeat: 'no-repeat',
              backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            })}
          />
          <Box
            sx={(theme) => ({
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '600px',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            })}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                margin: '32px',
              }}
            >
              <CircularProgress sx={{ width: '85px', height: '85px' }} />
              <Typography variant="h6" sx={{ fontSize: '23px', fontWeight: 400, marginTop: '48px' }}>
                Loading User Information
              </Typography>
            </Box>
          </Box>
        </Grid>
      </>
    );
  }

  if (loginWithTokenIsError) {
    console.error(loginWithTokenError);
    return (
      <ErrorAlert
        errorMessageTitle={'There was an error logging in with token.'}
        refetch={() => loginWithToken({ user_token: userToken })}
      />
    );
  }

  return <Navigate to={projectPathResolvers.projects()} />;
};
