import { useIsMobile } from '@/hooks';
import { useNavDrawerState } from '@/stores';
import { NavDrawerWidthCollapsed, NavDrawerWidthExpanded } from '@/utils/constants';
import { CircularProgress, Grid, Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';
import { createTeleporter } from 'react-teleporter';
import ErrorBoundary from '../ErrorBoundary';
import { MainIotCoreAppBar, SecondaryIotCoreAppBar } from '../IoTCoreAppBar';
import NavDrawer from './NavDrawer';

export const MenuTeleporter = createTeleporter();

export const DetailContainer: React.FC = () => {
  const isMobile = useIsMobile();
  const { navDrawerOpen } = useNavDrawerState();

  return (
    <ErrorBoundary>
      <MainIotCoreAppBar />
      <SecondaryIotCoreAppBar>
        <MenuTeleporter.Target />
      </SecondaryIotCoreAppBar>

      <NavDrawer />

      <Grid
        sx={(theme) => ({
          flexGrow: 1,
          ml: `${navDrawerOpen && !isMobile ? NavDrawerWidthExpanded : !isMobile ? NavDrawerWidthCollapsed : 0}px`,
          transition: theme.transitions?.create('margin', {
            easing: theme.transitions?.easing.sharp,
            duration: theme.transitions?.duration.leavingScreen,
          }),
        })}
      >
        <Box p={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Suspense
            fallback={
              <>
                <Box
                  width="100%"
                  height="calc(100vh - 48px)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
                <MenuTeleporter.Source>
                  <Skeleton width="100vw" />
                </MenuTeleporter.Source>
              </>
            }
          >
            <Outlet />
          </Suspense>
        </Box>
      </Grid>
    </ErrorBoundary>
  );
};
