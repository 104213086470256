import { axiosAdminUserAccount } from '@/lib/axiosAdminUserAccount';

import { UserLoginResponse } from '../types';

export type LoginCredentialsDTO = {
  email: string;
  password: string;
};

export const loginWithEmailAndPassword = async (data: LoginCredentialsDTO): Promise<UserLoginResponse> => {
  const resp = await axiosAdminUserAccount.post<UserLoginResponse>('/api/v/1/user/auth', data);
  return resp.data;
};
