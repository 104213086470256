import { FaApple, FaFacebook, FaTwitter } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { SiAuth0 } from 'react-icons/si';
import { Provider } from '../types';

export const knownProviders: Record<string, Provider> = {
  Google: {
    icon: FcGoogle,
    label: 'Google',
  },
  Facebook: {
    icon: FaFacebook,
    label: 'Facebook',
  },
  Auth0: {
    icon: SiAuth0,
    label: 'Auth0',
  },
  Apple: {
    icon: FaApple,
    label: 'Apple',
  },
  Twitter: {
    icon: FaTwitter,
    label: 'Twitter',
  },
};
