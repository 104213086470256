import { PalettePreference } from '@/types';
import storage from '@/utils/storage';
import { createTheme, PaletteMode, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const PaletteModePreferenceContext = createContext<{
  setPaletteModePreference: (mode: PalettePreference) => void;
  paletteModePreference: PalettePreference;
}>({
  setPaletteModePreference: () => {
    return;
  },
  paletteModePreference: 'system',
});

declare module '@mui/material/styles' {
  // Declare types for our custom theme variables
  interface PaletteOptions {
    landing: {
      button: string;
      text: string;
    };
    lightWhiteDarkBlack: string;
  }

  interface ZIndex {
    navDrawer: number;
  }
}

const darkModeMediaQuery = '(prefers-color-scheme: dark)';
const getPrimaryColor = (paletteMode: 'light' | 'dark') => ({
  light: paletteMode === 'light' ? '#448b85' : '#a3ffd6',
  main: paletteMode === 'light' ? '#166f67' : '#71e8b3',
  dark: paletteMode === 'light' ? '#0f4d48' : '#4fa27d',
});
const getSecondaryColor = (paletteMode: 'light' | 'dark') => ({
  light: paletteMode === 'light' ? '#356d87' : '#98ffff',
  main: paletteMode === 'light' ? '#034969' : '#52efef',
  dark: paletteMode === 'light' ? '#023349' : '#10b2b2',
});
const getTextColor = (paletteMode: 'light' | 'dark') => ({
  primary: paletteMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
  secondary: paletteMode === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.78)',
});
const getBackgroundColor = (paletteMode: 'light' | 'dark') => ({
  paper: paletteMode === 'light' ? '#ffffff' : '#424242',
  default: paletteMode === 'light' ? '#fafafa' : '#303030',
});

function getPaletteModeFromPreference(preference: PalettePreference): PaletteMode {
  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  return preference === 'system' ? (prefersDarkMode ? 'dark' : 'light') : preference;
}

export const MuiThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const initialPalettePreference = useStoredPaletteModePreference();
  const [palettePreference, setPalettePrefence] = useState(initialPalettePreference);
  const [paletteMode, setPaletteMode] = useState<PaletteMode>(getPaletteModeFromPreference(palettePreference));

  useEffect(() => {
    setPaletteMode(getPaletteModeFromPreference(palettePreference));
  }, [palettePreference]);

  useEffect(() => {
    const listener = (event: MediaQueryListEvent) => {
      if (palettePreference === 'system') {
        setPaletteMode(event.matches ? 'dark' : 'light');
      }
    };
    window.matchMedia(darkModeMediaQuery).addEventListener('change', listener);
    return () => window.matchMedia(darkModeMediaQuery).removeEventListener('change', listener);
  }, [palettePreference]);
  const theme = useMemo(
    () =>
      createTheme({
        zIndex: {
          // MUI's appBar is at 1100 by default, and their drawer is at 1200, but we need the nav drawer have a low zIndex to be beneath the app bar
          navDrawer: 1090,
        },
        palette: {
          mode: paletteMode,
          primary: getPrimaryColor(paletteMode),
          secondary: getSecondaryColor(paletteMode),
          text: getTextColor(paletteMode),
          landing: {
            button: '#71e8b3',
            text: 'rgba(255, 255, 255, 0.78)',
          },
          lightWhiteDarkBlack: paletteMode === 'light' ? 'white' : 'black',
          background: getBackgroundColor(paletteMode),
        },
      }),
    [paletteMode],
  );
  const paletteModePreferenceAPI = useMemo(
    () => ({
      paletteModePreference: palettePreference,
      setPaletteModePreference: (mode: PalettePreference) => {
        setPalettePrefence(mode);
        storage.setPaletteModePreference(mode);
      },
    }),
    [palettePreference],
  );

  return (
    <PaletteModePreferenceContext.Provider value={paletteModePreferenceAPI}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
      </ThemeProvider>
    </PaletteModePreferenceContext.Provider>
  );
};

function useStoredPaletteModePreference(): PalettePreference {
  const storedPaletteMode = storage.getPaletteModePreference();
  if (
    typeof storedPaletteMode === 'string' &&
    (storedPaletteMode === 'light' || storedPaletteMode === 'dark' || storedPaletteMode === 'system')
  ) {
    return storedPaletteMode;
  }
  return 'system';
}

/**
 * usePaletteModePreference
 * this hook is used as a getter/setter for the user's chosen palette preference which can be light | dark | system
 * this hook should only be used for displaying the current preference and updating it
 * if you want to check whether the palette is in light | dark mode just use useTheme from mui
 */
export function usePaletteModePreference() {
  return useContext(PaletteModePreferenceContext);
}
