import { Route, Routes } from 'react-router-dom';

import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { OIDCRegister } from './OIDCRegister';
import { OIDCToken } from './OIDCToken';
import { Register } from './Register';
import { ResetPassword } from './ResetPassword';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path={AuthRoutePaths.register} element={<Register />} />
      <Route path={AuthRoutePaths.login} element={<Login />} />
      <Route path={AuthRoutePaths.forgotPassword} element={<ForgotPassword />} />
      <Route path={AuthRoutePaths.resetPassword} element={<ResetPassword />} />
      <Route path={AuthRoutePaths.OIDCRegister} element={<OIDCRegister />} />
      <Route path={AuthRoutePaths.OIDCToken} element={<OIDCToken />} />
    </Routes>
  );
};

export const authPathResolver = {
  register: () => `/${AuthRoutePaths.index}/${AuthRoutePaths.register}`,
  login: () => `/${AuthRoutePaths.index}/${AuthRoutePaths.login}`,
  forgotPassword: () => `/${AuthRoutePaths.index}/${AuthRoutePaths.forgotPassword}`,
  OIDCRegister: () => `/${AuthRoutePaths.index}/${AuthRoutePaths.OIDCRegister}`,
  OIDCToken: () => `/${AuthRoutePaths.index}/${AuthRoutePaths.OIDCToken}`,
};

export const AuthRoutePaths = {
  index: 'auth',
  register: 'register',
  login: 'login',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',
  OIDCRegister: 'oidc-register',
  OIDCToken: 'oidc-token',
};
