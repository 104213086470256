import ExternalLink from '@/components/ExternalLink';
import { projectPathResolvers } from '@/features/projects';
import { useToggle } from '@/hooks';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { RegisterForm } from '../components/RegisterForm';

export const Register = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const x_gcp_marketplace_token = searchParams.get('x-gcp-marketplace-token');
  const { isOpen, open } = useToggle(false);

  return (
    <Layout title="Create an account">
      <Dialog open={isOpen}>
        <DialogContent>
          <Typography>
            Thanks for registering for ClearBlade IoT Core, to complete the process and access your production
            environment please visit:{' '}
            <ExternalLink href="https://iot.clearblade.com">https://iot.clearblade.com</ExternalLink>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => window.close()}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
      <Avatar
        sx={(theme) => ({
          m: 1,
          backgroundColor: theme.palette.grey[400],
        })}
      >
        <LockOutlinedIcon />
      </Avatar>
      <Typography variant="h5">Create an account</Typography>
      <Typography variant="body2" color="textSecondary">
        Make a new ClearBlade IoT Core account.
      </Typography>
      <Box mt={4}>
        <RegisterForm
          x_gcp_marketplace_token={x_gcp_marketplace_token ?? undefined}
          onSuccess={() => {
            if (x_gcp_marketplace_token) {
              open();
            } else {
              navigate(projectPathResolvers.projects());
            }
          }}
        />
      </Box>
    </Layout>
  );
};
