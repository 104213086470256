import { useUpdatePassword } from '@/features/auth/api/updatePassword';
import { useCloseableSnackbar, useIsMobile } from '@/hooks';
import { useNavDrawerState } from '@/stores';
import { NavDrawerWidthCollapsed, NavDrawerWidthExpanded, passwordRegex } from '@/utils/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { Key } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { AppBarHeading } from '../AppBarHeading';
import { TextHookForm } from '../HookFormFields';
import { SecondaryIotCoreAppBar } from '../IoTCoreAppBar';

export const AccountSettings = () => {
  const isMobile = useIsMobile();
  const { navDrawerOpen } = useNavDrawerState();

  return (
    <Box>
      <Box>
        <SecondaryIotCoreAppBar>
          <Box pl={2}>
            <AppBarHeading>Account Settings</AppBarHeading>
          </Box>
        </SecondaryIotCoreAppBar>
        <Drawer
          PaperProps={{
            sx: (theme) => ({
              ...(navDrawerOpen ? { width: NavDrawerWidthExpanded } : { width: NavDrawerWidthCollapsed }),
              zIndex: theme.zIndex.navDrawer,
              boxSizing: 'border-box',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              mb: 0,
              overflowX: 'hidden',
            }),
          }}
          sx={(theme) => ({
            ...(navDrawerOpen ? { width: NavDrawerWidthExpanded } : { width: NavDrawerWidthCollapsed }),
            flexShrink: 0,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            zIndex: theme.zIndex.navDrawer,
          })}
          variant="permanent"
          hideBackdrop
          open={navDrawerOpen}
          anchor="left"
        >
          <Toolbar variant="dense"></Toolbar> <Toolbar variant="dense"></Toolbar>
          <List dense>
            <ListItem disableGutters disablePadding sx={{ ...(isMobile ? { ml: 2 } : {}) }}>
              <ListItemButton selected={true}>
                <ListItemIcon sx={(theme) => ({ minWidth: theme.spacing(5) })}>
                  <Key />
                </ListItemIcon>
                <ListItemText
                  primary="Password Settings"
                  sx={{
                    whiteSpace: 'nowrap',
                    ...(isMobile || navDrawerOpen ? { visibility: 'visible' } : { visibility: 'hidden' }),
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>
      <Box p={4} ml={`${NavDrawerWidthExpanded}px`} sx={{ width: { sx: '100%', md: '50%' } }}>
        <PasswordResetForm />
      </Box>
    </Box>
  );
};

interface PasswordResetPayload {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

const schema = z
  .object({
    old_password: z.string(),
    new_password: z
      .string()
      .regex(
        passwordRegex,
        'Must contain at least 8 characters, 1 uppercase letter, 1 number, and 1 special character.',
      ),
    confirm_password: z
      .string()
      .regex(
        passwordRegex,
        'Must contain at least 8 characters, 1 uppercase letter, 1 number, and 1 special character.',
      ),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: "Passwords don't match",
    path: ['confirm_password'],
  });

const PasswordResetForm = () => {
  const updatePasswordQuery = useUpdatePassword({
    onSuccess: () => {
      reset();
      enqueueSnackbar('Password updated successfully', { variant: 'success' });
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar('Error updating password', { variant: 'error' });
    },
  });
  const { control, handleSubmit, reset } = useForm<PasswordResetPayload>({ resolver: zodResolver(schema) });
  const { enqueueSnackbar } = useCloseableSnackbar();

  return (
    <>
      <form
        onSubmit={handleSubmit(async (data) => {
          updatePasswordQuery.mutate(data);
        })}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography fontWeight="bold">Change Password</Typography>
          </Grid>
          <Grid item>
            <TextHookForm control={control} name="old_password" label="Old Password" fullWidth type="password" />
          </Grid>
          <Grid item>
            <TextHookForm control={control} name="new_password" label="New Password" fullWidth type="password" />
          </Grid>
          <Grid item>
            <TextHookForm
              control={control}
              name="confirm_password"
              label="Confirm Password"
              fullWidth
              type="password"
            />
          </Grid>
          <Grid item>
            <LoadingButton loading={updatePasswordQuery.isLoading} variant="contained" type="submit">
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
