import { SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import ExternalLink from '../ExternalLink';

export default function Copyright({ sx, linkColor }: { sx?: SxProps<Theme>; linkColor?: string }) {
  return (
    <Typography variant="caption" color="textSecondary" sx={sx}>
      Copyright ©
      <ExternalLink
        color={linkColor ?? 'inherit'}
        variant="caption"
        underline="always"
        href="https://www.clearblade.com"
        rel="noopener noreferrer"
      >
        ClearBlade
      </ExternalLink>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}
