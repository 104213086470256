import { TextHookForm } from '@/components/HookFormFields';
import { InputLabel } from '@/components/InputLabel';
import { InternalLink } from '@/components/InternalLink';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useFetchOIDCRegEndpoint } from '../api/fetchOIDCRegEndpoint';
import { authPathResolver } from '../routes';
import { OIDCRegisterFormData } from '../types';
import { knownProviders } from '../utils/providers';
import { ErrorAlert } from './ErrorAlert';
import { darken } from '@mui/system';

interface OIDCRegisterFormProps {
  providerName: string;
}

const schema = z.object({
  providerName: z.string().min(1, 'Provider name is required'),
  fname: z.string().min(1, 'Required'),
  lname: z.string().min(1, 'Required'),
});

export const OIDCRegisterForm = ({ providerName }: OIDCRegisterFormProps) => {
  const [hasError, setHasError] = useState(false);
  const fetchOIDCReg = useFetchOIDCRegEndpoint();
  const provider = knownProviders[providerName];
  const ProviderIcon = provider?.icon || null;
  const theme = useTheme();

  const { control, handleSubmit, getValues } = useForm<OIDCRegisterFormData>({
    defaultValues: {
      providerName: providerName,
      fname: '',
      lname: '',
    },
    resolver: zodResolver(schema),
  });

  const onSubmitForm: SubmitHandler<OIDCRegisterFormData> = async (data) => {
    console.log('fname:', data.fname, 'lname:', data.lname);
    if (data.fname !== '' && data.lname !== '') {
      Cookies.set(
        'oidc-token-callback-url',
        `${location.protocol}//${location.hostname}/iot-core${authPathResolver.OIDCToken()}`,
      );

      const mappedData = {
        provider_name: data.providerName,
        first_name: data.fname,
        last_name: data.lname,
      };

      fetchOIDCReg.mutate(mappedData);

      if (fetchOIDCReg.isError) {
        setHasError(true);
      }
    }
  };

  const handleRefetch = () => {
    const values = getValues();
    fetchOIDCReg.mutate({
      provider_name: values.providerName,
      first_name: values.fname,
      last_name: values.lname,
    });
    setHasError(false);
  };

  return (
    <Box>
      {hasError && (
        <ErrorAlert
          errorMessageTitle={'Something went wrong during your signup. Please wait a moment and try again.'}
          refetch={handleRefetch}
        />
      )}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={4}>
          <Grid item sm={12} xs={12}>
            <InputLabel label="First name" required />
            <TextHookForm autoFocus fullWidth size="small" control={control} name="fname" />
          </Grid>
          <Grid item sm={12} xs={12}>
            <InputLabel label="Last name" required />
            <TextHookForm fullWidth size="small" control={control} name="lname" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              startIcon={
                <Avatar sx={{ bgcolor: '#fff', width: '1.5rem', height: '1.5rem' }}>
                  {ProviderIcon ? (
                    <ProviderIcon style={{ color: 'black' }} />
                  ) : (
                    <Typography variant="body1" sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
                      {providerName[0]}
                    </Typography>
                  )}
                </Avatar>
              }
              loading={false}
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.grey[300],
                '&:hover': {
                  backgroundColor: darken(theme.palette.grey[300], 0.1),
                },
                color: '#000',
              }}
            >
              CONTINUE WITH {providerName}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <Box mt={4}>
        <Divider />
        <Box
          mt={4}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            justifyContent: 'space-between',
            textAlign: 'center',
          }}
        >
          <Typography>
            <InternalLink to={authPathResolver.register()}>Sign up another way</InternalLink>
          </Typography>
          <Typography>
            Already have an account? <InternalLink to={authPathResolver.login()}>Log in</InternalLink>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
