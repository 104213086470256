import { Link, LinkProps, Typography, TypographyTypeMap } from '@mui/material';

interface Props extends LinkProps {
  href: string;
  newTab?: boolean;
  variant?: TypographyTypeMap['props']['variant'];
  color?: string;
}

const ExternalLink: React.FC<Props> = ({ href, newTab = true, variant = 'body1', color, children, ...rest }) => {
  return (
    <Link
      href={href}
      rel={newTab ? 'noreferrer' : undefined}
      target={newTab ? '_blank' : undefined}
      sx={(theme) => {
        const defaultColor = color ?? theme.palette.secondary.light;
        return {
          color: defaultColor,
          textDecorationColor: defaultColor,
          '&:hover': { textDecorationColor: defaultColor, opacity: 0.8 },
        };
      }}
      {...rest}
    >
      <Typography
        component={'span'}
        sx={(theme) => {
          const defaultColor = color ?? theme.palette.secondary.light;
          return {
            display: 'inline', // so that the link doesn't take up remaining horizontal space
            color: defaultColor,
          };
        }}
        variant={variant}
      >
        {children}
      </Typography>
    </Link>
  );
};

export default ExternalLink;
