import { Avatar, Box, Button, CircularProgress, Divider, Grid, Typography, useTheme } from '@mui/material';
import { darken } from '@mui/system';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchOIDCAuthEndpoint } from '../api/fetchOIDCAuthEndpoint';
import { useProviderList } from '../api/useProviderList';
import { authPathResolver } from '../routes';
import { knownProviders } from '../utils/providers';
import { ErrorAlert } from './ErrorAlert';

interface cbProviderButtonsProps {
  action: 'Log in' | 'Sign up';
}

export const ProviderButtons = ({ action }: cbProviderButtonsProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const fetchOIDCAuth = useFetchOIDCAuthEndpoint();
  const [registeredProviderNames, setRegisteredProviderNames] = useState<string[]>([]);
  const { data, isLoading, isError, refetch } = useProviderList();
  const providerButtonBgColor = theme.palette.grey[300];
  const handleOIDCButtonClick = async (providerName: string) => {
    if (action === 'Sign up') {
      navigate(authPathResolver.OIDCRegister(), { state: { providerName: providerName } });
    } else {
      Cookies.set(
        'oidc-token-callback-url',
        `${location.protocol}//${location.hostname}/iot-core${authPathResolver.OIDCToken()}`,
      );
      const data = {
        provider_name: providerName,
      };
      fetchOIDCAuth.mutate(data);
    }
  };

  useEffect(() => {
    if (data) {
      setRegisteredProviderNames(data);
    }
  }, [data]);

  if (isLoading) {
    return (
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1.5rem',
            width: '100%',
            mt: 4,
            mb: 4,
          }}
        >
          <Divider sx={{ flexGrow: 1 }} />
          <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 500 }}>
            OR
          </Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Box>
        <ErrorAlert
          errorMessageTitle={'Error loading list of third-party login options.'}
          refetch={refetch}
          errorMessageBody={
            'We were unable to display a list of the third-party login methods available for this system.'
          }
        />
      </Box>
    );
  }

  return (
    <Box>
      {registeredProviderNames.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1.5rem',
            width: '100%',
            mt: 4,
            mb: 4,
          }}
        >
          <Divider sx={{ flexGrow: 1 }} />
          <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 500 }}>
            OR
          </Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Box>
      )}
      <Grid container spacing={4}>
        {registeredProviderNames.map((providerName, index) => {
          const provider = knownProviders[providerName];
          const ProviderIcon = provider?.icon || null;
          return (
            <Grid item xs={12} key={index}>
              <Button
                onClick={() => handleOIDCButtonClick(providerName)}
                sx={{
                  width: '100%',
                  backgroundColor: providerButtonBgColor,
                  '&:hover': {
                    backgroundColor: darken(providerButtonBgColor, 0.1),
                  },
                  color: '#000',
                }}
                variant="contained"
                startIcon={
                  <Avatar sx={{ bgcolor: '#fff', width: '1.5rem', height: '1.5rem' }}>
                    {ProviderIcon ? (
                      <ProviderIcon style={{ color: 'black' }} />
                    ) : (
                      <Typography variant="body1" sx={{ fontSize: '1.25rem', fontWeight: 500, color: '#000' }}>
                        {providerName[0]}
                      </Typography>
                    )}
                  </Avatar>
                }
              >
                {action} with {provider?.label ?? providerName}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
