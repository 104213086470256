import { useIsMobile } from '@/hooks';
import create from 'zustand';
import shallow from 'zustand/shallow';

interface NavDrawerSlice {
  navDrawerOpen: { desktop: boolean; mobile: boolean };
  toggleNavDrawer: (isMobile: boolean) => void;
}

const useNavDrawerStore = create<NavDrawerSlice>((set) => ({
  navDrawerOpen: { desktop: true, mobile: false },
  toggleNavDrawer: (isMobile) => {
    set(({ navDrawerOpen: { mobile, desktop } }) => {
      return isMobile
        ? { navDrawerOpen: { desktop, mobile: !mobile } }
        : { navDrawerOpen: { mobile, desktop: !desktop } };
    });
  },
}));

export const useNavDrawerState = () => {
  const isMobile = useIsMobile();
  const [{ mobile, desktop }, toggleNavDrawer] = useNavDrawerStore(
    (state) => [state.navDrawerOpen, state.toggleNavDrawer],
    shallow,
  );

  return { navDrawerOpen: isMobile ? mobile : desktop, toggleNavDrawer: () => toggleNavDrawer(isMobile) };
};
