import { HelpCenterOutlined } from '@mui/icons-material';
import { Box, Typography, TypographyProps } from '@mui/material';

function TypographyWithHelpText({
  msg,
  helpText,
  ...typographyProps
}: TypographyProps & { msg: string | JSX.Element; helpText?: JSX.Element | string }) {
  return (
    <Typography
      sx={{ display: 'flex', alignItems: 'center', justify: 'center', flexWrap: 'nowrap' }}
      {...typographyProps}
    >
      {msg}
      {helpText && <HelpCenterOutlined sx={{ ml: 1 }} />}
    </Typography>
  );
}

const LabelContent = ({
  label,
  required,
  optional,
  variant,
  color,
}: {
  label: string | JSX.Element;
  required?: boolean;
  optional?: boolean;
  variant?: TypographyProps['variant'];
  color?: TypographyProps['color'];
}) => {
  return (
    <Box component="span" display="flex" alignItems="center">
      <b>
        {label}
        {required && '*'}
      </b>
      {optional && !required && (
        <Typography sx={{ pl: 0.5 }} variant={variant ?? 'body2'} color={color ?? 'textSecondary'}>
          Optional
        </Typography>
      )}
    </Box>
  );
};

export const InputLabel = ({
  name,
  label,
  helpText,
  required,
  optional,
  variant,
  color,
}: {
  name?: string;
  label: string | JSX.Element;
  helpText?: string | JSX.Element;
  required?: boolean;
  optional?: boolean;
  variant?: TypographyProps['variant'];
  color?: TypographyProps['color'];
}) => {
  return (
    <label htmlFor={name}>
      {helpText ? (
        <TypographyWithHelpText
          variant={variant ?? 'body2'}
          color={color ?? 'textSecondary'}
          msg={<LabelContent label={label} required={required} optional={optional} />}
          helpText={helpText}
        ></TypographyWithHelpText>
      ) : (
        <Typography variant={variant ?? 'body2'} color={color ?? 'textSecondary'} sx={{ minHeight: 24 }}>
          {<LabelContent label={label} required={required} optional={optional} />}
        </Typography>
      )}
    </label>
  );
};
