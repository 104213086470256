import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';
import * as Sentry from '@sentry/react';

declare global {
  interface Window {
    COMMIT_HASH: string;
  }
}

// attach COMMIT_HASH to window so that devs can find out which version they're looking at by logging this var in the JS console
window.COMMIT_HASH = COMMIT_HASH;
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    release: 'iot-core@' + COMMIT_HASH,
    dsn: 'https://250f1383cb9c4283925900ff797ebb87@o541399.ingest.sentry.io/6771642',
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
